<template>
  <div class="analysis container">
    <div v-if="avgScore !== undefined && avgScore.score.avg_english_reading_score">
      <div class="row total">
        <!-- Your Total Score -->
        <div class="col-md-6 col-sm-12 total_score print_div">
          <h4 class="title">
            Students's Total Scores
          </h4>
          <div class="total_above row">
            <p class="col-md-8 score">
              {{
                showScore(avgScore.score.avg_english_reading_score) * 10 +
                  showScore(avgScore.score.avg_english_writing_score) * 10 +
                  showScore(avgScore.score.avg_math_score)
              }}
              <small class="differt-score">
                <span
                  v-if="progress.progress_total_score > 0"
                  class="text-success"
                >
                  <i class="fas fa-caret-up"></i>
                  {{ Math.abs(progress.progress_total_score) }}
                </span>
                <span
                  v-if="progress.progress_total_score < 0"
                  class="text-danger"
                >
                  <i class="fas fa-caret-down"></i>
                  {{ Math.abs(progress.progress_total_score) }}
                </span>
              </small>
            </p>
            <p class="col-sm-4 t_score">400 to 1600</p>
          </div>
        </div>
        <!-- <div class="col-md-6 col-sm-12 essay print_div">
          <h4 class="title">
            Essay Scores
          </h4>
          <div style="padding-top:20px;" class="row">
            <div class="col-md-4 essay_score">
              <div class="row">
                <div class="col-md-6">
                  <b style="font-size:23px">
                    <span>N/A</span>
                  </b>
                </div>
                <div class="col-md-6 e_score">2 to 8</div>
              </div>
              <p>Reading</p>
            </div>
            <div class="col-md-4 essay_score">
              <div class="row">
                <div class="col-md-6">
                  <b style="font-size:23px">
                    <span>N/A</span>
                  </b>
                </div>
                <div class="col-md-6 e_score">2 to 8</div>
              </div>

              <p>Analysis</p>
            </div>
            <div class="col-md-4 essay_score">
              <div class="row">
                <div class="col-md-6">
                  <b style="font-size:23px">
                    <span>N/A</span>
                  </b>
                </div>
                <div class="col-md-6 e_score">2 to 8</div>
              </div>
              <p>Writing</p>
            </div>
          </div>
        </div> -->
      </div>
      <div class="total section">
        <h4 class="title">
          Section Scores
        </h4>
        <div class="row">
          <div class="col-md-6 col-sm-12 total_score print_div">
            <div class="total_above row">
              <p class="col-md-8 score">
                {{
                  (showScore(avgScore.score.avg_english_reading_score) +
                    showScore(avgScore.score.avg_english_writing_score)) *
                    10
                }}
                <small class="differt-score">
                  <span
                    v-if="progress.progress_section_english_score > 0"
                    class="text-success"
                  >
                    <i class="fas fa-caret-up"></i>
                    {{ Math.abs(progress.progress_section_english_score) }}
                  </span>
                  <span
                    v-if="progress.progress_section_english_score < 0"
                    class="text-danger"
                  >
                    <i class="fas fa-caret-down"></i>
                    {{ Math.abs(progress.progress_section_english_score) }}
                  </span>
                </small>
              </p>
              <p class="col-sm-4 t_score">200 to 800</p>
            </div>
            <div class="total_progress">
              <h6>Evidence-Based Reading and Writing Score</h6>
            </div>
          </div>
          <div class="col-md-6 col-sm-12 total_score print_div">
            <div class="total_above row">
              <p class="col-md-8 score">
                {{ showScore(avgScore.score.avg_math_score) }}
                <small class="differt-score">
                  <span
                    v-if="progress.progress_section_math_score > 0"
                    class="text-success"
                  >
                    <i class="fas fa-caret-up"></i>
                    {{ Math.abs(progress.progress_section_math_score) }}
                  </span>
                  <span
                    v-if="progress.progress_section_math_score < 0"
                    class="text-danger"
                  >
                    <i class="fas fa-caret-down"></i>
                    {{ Math.abs(progress.progress_section_math_score) }}
                  </span>
                </small>
              </p>
              <p class="col-sm-4 t_score">200 to 800</p>
            </div>
            <div class="total_progress">
              <h6>Math Score</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr />

    <div class="total">
      <h4 class="title">Student's Detail Scores</h4>
      <el-collapse-transition>
        <div v-show="showScores">
          <table class="table table-border">
            <thead>
              <tr>
                <th>Student</th>
                <th>Score</th>
                <th>Super Score</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(student, index) in analysis.scores" :key="index">
                <td>
                  <a
                    :href="'/sat/result?user_exam_id=' + student.user_exam_id"
                    target="_blank"
                  >
                    <span v-if="student.user_name">
                      {{ student.user_name }}
                    </span>
                    <span v-else>
                      {{ student.first_name }} {{ student.last_name }}
                    </span>
                  </a>
                </td>
                <td>
                  <sat-score
                    v-if="student.reading_score && student.writing_score"
                    :rscore="student.reading_score * 10"
                    :wscore="student.writing_score * 10"
                    :escore="student.section_english_score"
                    :mscore="student.section_math_score"
                    :tscore="student.total_score"
                    :rscore_up="
                      showUp(student, student.last, 'reading_score', 10)
                    "
                    :gscore_up="
                      showUp(student, student.last, 'writing_score', 10)
                    "
                    :escore_up="
                      showUp(student, student.last, 'section_english_score', 1)
                    "
                    :mscore_up="
                      showUp(student, student.last, 'section_math_score', 1)
                    "
                    :score_up="showUp(student, student.last, 'total_score', 1)"
                    :canEdit="false"
                    userExamId=""
                  ></sat-score>
                </td>
                <td>
                  <b style="font-size:20px">
                    {{ student.max_total_score }}
                  </b>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </el-collapse-transition>
      <div
        class="demo-block-control"
        style="left: 0px;"
        @click="showScores = !showScores"
      >
        <div v-show="!showScores">
          <i class="el-icon-caret-bottom"></i>
          <span style="">查看詳情</span>
        </div>
        <div v-show="showScores">
          <i class="el-icon-caret-top"></i>
          <span style="">收起</span>
        </div>
      </div>
    </div>
    <hr />
    <div class="total">
      <h4 class="title">
        Score Breakdown
        <el-button
          size="small"
          type="success"
          v-if="showPer"
          @click="showPer = false"
        >
          Show details
        </el-button>
        <el-button size="small" type="danger" v-else @click="showPer = true">
          Hide details
        </el-button>
      </h4>
      <table id="testresult" class="table table-bordered">
        <thead>
          <tr>
            <td class="green-td"></td>
            <th class="green-td right-border" colspan="2"><b>Reading</b></th>
            <th class="green-td right-border" colspan="2"><b>Writing</b></th>
            <th class="green-td right-border" colspan="2"><b>Math</b></th>
            <th class="green-td right-border" colspan="2">
              <b>Math</b> <i class="fa fa-calculator"></i>
            </th>
          </tr>
          <tr>
            <td class="green-td">#</td>
            <td class="green-td right-border" colspan="2">Section1</td>
            <td class="green-td right-border" colspan="2">Section2</td>
            <td class="green-td right-border" colspan="2">Section3</td>
            <td class="green-td right-border" colspan="2">Section4</td>
          </tr>
        </thead>
        <tbody v-if="analysis.answers !== undefined">
          <tr v-for="index in analysis.answers.reading.length" :key="index">
            <td class="green-td">
              <b>{{ index }}</b>
            </td>
            <!-- Reading -->
            <td>
              <div v-if="analysis.answers.reading[index - 1] !== undefined">
                <!-- <router-link 
                  target="_blank" 
                  :to="{
                    path:'/tests/sat/test/' + $route.query.test +  '/section/reading/question/'+ analysis.answers.reading[index - 1].id + '/view',
                    query: $route.query
                  }"
                >
                  {{ analysis.answers.reading[index - 1].answers[0][0] }}
                </router-link> -->
                <b class="text-success">{{ analysis.answers.reading[index - 1].answers[0][0] }}</b>
              </div>
            </td>
            <td>
              <div
                v-if="analysis.answers.reading[index - 1] !== undefined"
                :class="
                  showAnswerCorrectPer(
                    analysis.answers.reading[index - 1].correct,
                    analysis.answers.reading[index - 1].total
                  )
                "
              >
                <div v-if="showPer">
                  <el-tooltip
                    placement="top"
                  >
                  <div slot="content" v-html="showWrongAns(analysis.answers.reading[index - 1].answers[0][0], analysis.answers.reading[index - 1].student_answers)"></div>
                  <b
                    >{{
                      getPer(
                        analysis.answers.reading[index - 1].correct /
                          analysis.answers.reading[index - 1].total
                      )
                    }}%
                  </b>
                  </el-tooltip>
                </div>
                <div v-else>
                  <el-tooltip
                    :content="
                      showName(true, analysis.answers.reading[index - 1])
                    "
                    placement="top"
                  >
                    <b class="text-success">
                      {{ analysis.answers.reading[index - 1].correct }}
                    </b>
                  </el-tooltip>
                  /
                  <b>
                    {{ analysis.answers.reading[index - 1].omit }}
                  </b>
                  /
                  <el-tooltip
                    :content="
                      showName(false, analysis.answers.reading[index - 1])
                    "
                    placement="top"
                  >
                    <b class="text-danger">
                      {{ analysis.answers.reading[index - 1].wrong }}
                    </b>
                  </el-tooltip>
                </div>
              </div>
            </td>
            <!-- Writing -->
            <td>
              <div v-if="analysis.answers.writing[index - 1] !== undefined">
                <!-- <router-link 
                  target="_blank" 
                  :to="{
                    path:'/tests/sat/test/' + $route.query.test +  '/section/writing/question/'+ analysis.answers.writing[index - 1].id + '/view',
                    query: $route.query
                  }"
                >
                  {{ analysis.answers.writing[index - 1].answers[0][0] }}
                </router-link> -->
                <b class="text-success">{{ analysis.answers.writing[index - 1].answers[0][0] }}</b>
              </div>
            </td>
            <td>
              <div
                v-if="analysis.answers.writing[index - 1] !== undefined"
                :class="
                  showAnswerCorrectPer(
                    analysis.answers.writing[index - 1].correct,
                    analysis.answers.writing[index - 1].total
                  )
                "
              >
                <div v-if="showPer">
                   <el-tooltip
                    placement="top"
                  >
                    <div slot="content" v-html="showWrongAns(analysis.answers.writing[index - 1].answers[0][0], analysis.answers.writing[index - 1].student_answers)"></div>
                    <b
                      >{{
                        getPer(
                          analysis.answers.writing[index - 1].correct /
                            analysis.answers.writing[index - 1].total
                        )
                      }}%
                    </b>
                  </el-tooltip>
                </div>
                <div v-else>
                  <el-tooltip
                    :content="
                      showName(true, analysis.answers.writing[index - 1])
                    "
                    placement="top"
                  >
                    <b class="text-success">
                      {{ analysis.answers.writing[index - 1].correct }}
                    </b>
                  </el-tooltip>
                  /
                  <b>
                    {{ analysis.answers.writing[index - 1].omit }}
                  </b>
                  /
                  <el-tooltip
                    :content="
                      showName(false, analysis.answers.writing[index - 1])
                    "
                    placement="top"
                  >
                    <b class="text-danger">
                      {{ analysis.answers.writing[index - 1].wrong }}
                    </b>
                  </el-tooltip>
                </div>
              </div>
            </td>
            <!-- Math -->
            <td>
              <div v-if="analysis.answers.math[index - 1] !== undefined">
                <!-- <router-link 
                  target="_blank" 
                  :to="{
                    path:'/tests/sat/test/' + $route.query.test +  '/section/math/question/'+ analysis.answers.writing[index - 1].id + '/view',
                    query: $route.query
                  }"
                >
                  {{ analysis.answers.math[index - 1].answers[0][0] }}
                </router-link> -->
                <b class="text-success">{{ analysis.answers.math[index - 1].answers[0][0] }}</b>
              </div>
            </td>
            <td>
              <div
                v-if="analysis.answers.math[index - 1] !== undefined"
                :class="
                  showAnswerCorrectPer(
                    analysis.answers.math[index - 1].correct,
                    analysis.answers.math[index - 1].total
                  )
                "
              >
                <div v-if="showPer">
                  <el-tooltip
                    placement="top"
                  >
                    <div slot="content" v-html="showWrongAns(analysis.answers.math[index - 1].answers[0][0], analysis.answers.math[index - 1].student_answers)"></div>
                    <b
                      >{{
                        getPer(
                          analysis.answers.math[index - 1].correct /
                            analysis.answers.math[index - 1].total
                        )
                      }}%
                    </b>
                  </el-tooltip>
                </div>
                <div v-else>
                  <el-tooltip
                    :content="showName(true, analysis.answers.math[index - 1])"
                    placement="top"
                  >
                    <b class="text-success">
                      {{ analysis.answers.math[index - 1].correct }}
                    </b>
                  </el-tooltip>
                  /
                  <b>
                    {{ analysis.answers.math[index - 1].omit }}
                  </b>
                  /
                  <el-tooltip
                    :content="showName(false, analysis.answers.math[index - 1])"
                    placement="top"
                  >
                    <b class="text-danger">
                      {{ analysis.answers.math[index - 1].wrong }}
                    </b>
                  </el-tooltip>
                </div>
              </div>
            </td>

            <!-- Math Calculator -->
            <td>
              <div
                v-if="
                  analysis.answers['math calculator'][index - 1] !== undefined
                "
              >
                <!-- <router-link 
                  target="_blank" 
                  :to="{
                    path:'/tests/sat/test/' + $route.query.test +  '/section/mathc/question/'+ analysis.answers['math calculator'][index - 1].id + '/view',
                    query: $route.query
                  }"
                >
                  {{
                    analysis.answers["math calculator"][index - 1].answers[0][0]
                  }} 
                </router-link> -->
                <b class="text-success">{{ analysis.answers["math calculator"][index - 1].answers[0][0] }}</b>
              </div>
            </td>
            <td>
              <div
                v-if="
                  analysis.answers['math calculator'][index - 1] !== undefined
                "
                :class="
                  showAnswerCorrectPer(
                    analysis.answers['math calculator'][index - 1].correct,
                    analysis.answers['math calculator'][index - 1].total
                  )
                "
              >
                <div v-if="showPer">
                  <el-tooltip
                    placement="top"
                  >
                    <div slot="content" v-html="showWrongAns(analysis.answers['math calculator'][index - 1].answers[0][0], analysis.answers['math calculator'][index - 1].student_answers)"></div>
                    <b
                      >{{
                        getPer(
                          analysis.answers["math calculator"][index - 1].correct /
                            analysis.answers["math calculator"][index - 1].total
                        )
                      }}%
                    </b>
                  </el-tooltip>
                </div>
                <div v-else>
                  <el-tooltip
                    :content="
                      showName(
                        true,
                        analysis.answers['math calculator'][index - 1]
                      )
                    "
                    placement="top"
                  >
                    <b class="text-success">
                      {{
                        analysis.answers["math calculator"][index - 1].correct
                      }}
                    </b>
                  </el-tooltip>
                  /
                  <b>
                    {{ analysis.answers["math calculator"][index - 1].omit }}
                  </b>
                  /
                  <el-tooltip
                    :content="
                      showName(
                        false,
                        analysis.answers['math calculator'][index - 1]
                      )
                    "
                    placement="top"
                  >
                    <b class="text-danger">
                      {{ analysis.answers["math calculator"][index - 1].wrong }}
                    </b>
                  </el-tooltip>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import SatScore from "@/views/sessionClasses/components/SatScore.vue";

export default {
  metaInfo() {
    return {
      title: "Analysis - Detail - Ivy-Way Academy"
    };
  },

  components: {
    SatScore
  },

  mixins: [],

  props: ["analysis", "avgScore"],
  data() {
    return {
      showScores: false,
      showPer: true,
      showAnswer: true,
      progress: {
        progress_total_score: 0,
        progress_section_english_score: 0,
        progress_section_math_score: 0,
        progress_reading_score: 0,
        progress_math_score: 0,
        progress_aih_score: 0,
        progress_ais_score: 0,
        progress_coe_score: 0,
        progress_wic_score: 0,
        progress_eoi_score: 0,
        progress_hoa_score: 0,
        progress_psada_score: 0,
        progress_ptam_score: 0,
        progress_sec_score: 0,
        progress_essay_reading: 0,
        progress_essay_analysis: 0,
        progress_essay_writing: 0
      },
      progressType: 1,
      sectionArr: {
        reading: "Reading",
        writing: "Writing",
        math: "Math",
        "math calculator": " Math Calculator <i class='fa fa-calculator'>"
      }
    };
  },
  computed: {},
  watch: {},

  mounted() {},

  methods: {
    showScore(total) {
      let score = "";
      score = Math.round(parseFloat(total));
      return score;
    },
    getPer(val) {
      var str = Number(val * 100).toFixed(2);
      return str;
    },
    showWrongAns(cAns,studentsAnswer){
      let text = "";
      let ctext = "";
      let wtext = "";
      let hasCorrect =false;
      let hasWrong =false;
      let i = 0;
      for(let key  in studentsAnswer){
        let name = "";
        studentsAnswer[key].forEach((student,index) => {
          if(studentsAnswer[key].length === index+1){
            name+= student;
          } else {
            name+= student +", ";
          }
          
        });
        if(key == cAns) {
          hasCorrect = true;
          ctext += `Correct <br /><b class="text-success">${cAns} (${name})</b>`;
        }else if(key != cAns && key != "X" ){
          hasWrong = true;
          if(i === 0){
            wtext +=`Wrong <br /><b class="text-danger">${key} (${name})</b>`;
          } else {
            wtext +=` <br /><b class="text-danger">${key} (${name})</b>`;
          };
          i++;
        }
      }
      if(hasCorrect && hasWrong){
        text = ctext + "<br /><br />" + wtext;
      }
      if(!hasCorrect && hasWrong){
        text =  wtext;
      }
      if(hasCorrect && !hasWrong){
        text =  ctext;
      }
      return text;
    },
    showName(isCorrect, obj) {
      let students = [];
      if (isCorrect) {
        for (let key in obj.student_answers) {
          if (obj.answers[0].indexOf(key) > -1) {
            students = students.concat(obj.student_answers[key]);
          }
        }
      } else {
        for (let key in obj.student_answers) {
          if (obj.answers[0].indexOf(key) < 0) {
            students = students.concat(obj.student_answers[key]);
          }
        }
      }
      if (students.length > 0) {
        return students.join(", ");
      } else {
        return "None";
      }
    },
    showUp(now, last, section, count) {
      if (last === null) {
        return 0;
      } else {
        return (now[section] - last[section]) * count;
      }
    },
    showAnswerCorrectPer(correct, total) {
      let per = this.getPer(correct / total);

      if (parseFloat(per) < 50) {
        return "correct-style correct-0";
      } else if (parseFloat(per) >= 50 && parseInt(per) < 80) {
        return "correct-style correct-50";
      } else if (parseFloat(per) >= 80 && parseInt(per) < 100) {
        return "correct-style correct-80";
      } else if (parseInt(per) === 100) {
        return "correct-style correct-100";
      }
    }
  }
};
</script>

<style scoped>
hr {
  border-top: 2px solid #42a16a;
}
.analysis {
  padding: 20px;
}
.title {
  font-size: 30px;
}
.total p,
.total ul {
  margin: 0px;
}

.total {
  margin-top: 30px;
}
.total .title {
  font-size: 30px;
  color: #43a06b;
  margin-bottom: 20px;
}
.total_under {
  padding: 0px;
}
.total_above .score {
  font-size: 60px;
  color: #222;
  font-weight: 700;
  line-height: 70px;
  height: 70px;
}
.total .score + p {
  margin-top: 25px;
  height: 40px;
  line-height: 40px;
  padding-left: 20px;
  border-left: 4px solid #ccc;
}
.sat_left > div > span {
  display: inline-block;
  position: absolute;
  left: 290px;
  top: 28px;
}
.sat_left > div > p:first-child {
  font-size: 140px;
  font-weight: 700;
  color: #43a06b;
  line-height: 140px;
  margin-bottom: 0px;
}
.sat_right {
  padding-left: 0px;
  padding-top: 30px;
  border-bottom: 1px solid#DCDFE6;
}
.sat_right ul {
  clear: both;
}
.sat_right ul li {
  font-size: 20px;
  line-height: 30px;
}
.sat_right img {
  width: 90%;
}
.green-td {
  width: 96px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  color: #fff;
  background-color: #43a06b;
}
.table td,
.table th {
  height: 20px;
  padding: 2px 5px;
  text-align: center;
  vertical-align: middle;
  border: #4fb81e 2px solid;
}
#testresult.table td,
#testresult.table th {
  padding: 0;
  min-width: 30px;
  font-weight: 500;
  border: #dee2e6 2px solid;
}
.demo-block-control {
  /* border-top: 1px solid #eaeefb; */
  height: 44px;
  line-height: 44px;
  box-sizing: border-box;
  background-color: #fff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  text-align: center;
  margin-top: -1px;
  color: #4fb81e;
  cursor: pointer;
  position: relative;
}
.demo-block-control:hover {
  /* color: #409eff; */
  background-color: #f9fafc;
}

.correct-100 {
  background-color: #399460c2;
  color: white;
}
.correct-80 {
  color: #399460c2;
}
.correct-50 {
  color: #f56c6c;
}
.correct-0 {
  background-color: #f56c6c;
  color: white;
}
.correct-style {
  padding: 8px 5px;
}
</style>
